export const phoneNumberPreselections = [
  { country: 'CZE', locale: 'cs', preselection: '+420' },
  { country: 'USA', locale: 'en', preselection: '+1' },
  { country: 'DEU', locale: 'de', preselection: '+49' },
  { country: 'SVK', locale: 'sk', preselection: '+421' },
  { country: 'POL', locale: 'pl', preselection: '+48' },
  { country: 'HUN', locale: 'hu', preselection: '+36' },
  { country: 'NLD', locale: 'nl', preselection: '+31' },
  { country: 'UKR', locale: 'ua', preselection: '+380' },
  { country: 'BEL', locale: 'bg', preselection: '+359' },
  { country: 'RUS', locale: 'ru', preselection: '+7' },
  { country: 'VNM', locale: 'vi', preselection: '+84' },
];
