<template>
  <v-container class="title-input-block mx-auto" fluid>
    <v-row align="center" justify="space-between" class="px-4 py-2" no-gutters>
      <v-col class="col-12 col-md-3 text-left">
        <span class="subtitle-1 font-weight-regular black--text">
          {{ $t('contract.workflow.predefined_title') }}
        </span>
      </v-col>

      <v-col class="col-12 col-md-9 px-0 pb-1">
        <input
          v-model="templateTitle"
          class="input-block"
          :placeholder="`${$t('contract.workflow.predefined_placeholder')}`"
          hide-details
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="px-4 pb-2" no-gutters>
      <v-col class="col-12 col-md-3"></v-col>

      <v-col
        class="col-12 col-md-9 subtitle-2 title-input-block__info font-weight-regular text-left"
      >
        {{ $t('contract.workflow.predefined.info') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PredefinedWorkflowTitleInput',
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    templateTitle: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.title-input-block {
  width: 100%;
  background-color: #f8fbff;
  border: 1px solid #dde6f4;
  border-radius: 6px;

  &__info {
    color: #8c93a0;
  }
}

.input-block {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #b2bbcb;
  border-radius: 6px;
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
}

.input-block {
  :deep(&) .v-input__slot::before {
    border-style: none !important;
  }

  :deep(&) .v-input__slot::after {
    border-style: none !important;
  }
}

.prepared-name {
  background-color: #e8edf5;
  border: 1px solid #b4c1d6;
  border-radius: 6px;
  margin: 0.1rem 0.3rem;
  padding: 0.1rem 0.5rem;
}
</style>
