<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs, value }">
      <v-btn class="px-2" text v-on="on" v-bind="attrs">
        <LanguageFlag class="language-select__flag" :locale-data="selectedLanguage" />

        <span class="language-select__title ml-2 mr-3">{{ selectedLanguageTitle }}</span>

        <v-icon color="primary">
          {{ `mdi-chevron-${value ? 'up' : 'down'}` }}
        </v-icon>
      </v-btn>
    </template>

    <v-list class="options-menu">
      <v-list-item
        v-for="option in availableLocales"
        :key="option.locale"
        @click="onChangeLocale(option)"
      >
        <v-list-item-content>
          <v-row align="center" justify="center" no-gutters>
            <v-col class="col-6">
              <LanguageFlag class="language-select__flag" :locale-data="option" />
            </v-col>

            <v-col class="col-6">
              <span class="language-select__title">
                {{ option.locale.toUpperCase() }}
              </span>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { defineComponent } from 'vue';
import { getLocales } from '@/plugins/i18n/Locales';
import { useContractStore } from '@/features/contracts/stores/contract';
import LanguageFlag from '@/common/components/LanguageFlag';

export default defineComponent({
  name: 'ContractLanguageSelect',
  components: {
    LanguageFlag,
  },
  setup() {
    const contractStore = useContractStore();

    return {
      contractStore,
    };
  },
  data() {
    return {
      selectedLanguage: null,
    };
  },
  computed: {
    availableLocales() {
      return getLocales(false).filter((option) => {
        return option.locale !== this.selectedLanguage?.locale;
      });
    },
    selectedLanguageTitle() {
      return this.selectedLanguage?.locale?.toUpperCase();
    },
  },
  created() {
    this.selectedLanguage =
      this.availableLocales?.find((locale) => locale.locale === this.contractStore.data?.locale) ||
      this.availableLocales[0];
  },
  methods: {
    onChangeLocale(selectedOption) {
      this.selectedLanguage = selectedOption;
      this.contractStore.data.locale = selectedOption.locale;
    },
  },
});
</script>

<style lang="scss" scoped>
.language-select {
  &__flag {
    width: 17.78px !important;
    height: 12px !important;

    img {
      width: 17.78px;
      max-height: 12px;
      height: auto;
      object-fit: contain;
    }
  }

  &__title {
    height: 17px;
    color: #868e96;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.options-menu {
  border: 1px solid #7031b4;
  border-radius: 4px;
  background-color: #ffffff;

  &__content--text {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__content--icon {
    height: 14.95px;
    width: 13px;
  }
}
</style>
