import { defineStore } from 'pinia';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';

export const useFirstStepValidationStore = defineStore('firstStepValidation', {
  getters: {
    isDisabledNextStep: () => {
      const contractStore = useContractStore();
      const contractDocumentsStore = useContractDocumentsStore();
      const signIdentitiesStore = useSignIdentitiesStore();

      return (
        contractStore.errorOccured ||
        contractDocumentsStore.errorOccured ||
        signIdentitiesStore.errorOccured
      );
    },
    errorOccured: (state) => {
      return state.isDisabledNextStep;
    },
  },
});
