import { defineStore } from 'pinia';
import { fetchCurrentSignIdentity } from '@/features/contracts/services/signIdentitiesService';

export const useCurrentSignIdentityStore = defineStore('currentSignIdentity', {
  state: () => ({
    data: {},
  }),
  actions: {
    fetchSignIdentity(contractId) {
      return fetchCurrentSignIdentity(contractId).then((resp) => {
        this.data = resp;
      });
    },
  },
  getters: {
    signIdentity: (state) => {
      return state.data;
    },
  },
});
