<template>
  <PageLoader v-if="isLoading" />

  <v-container v-else class="px-5 px-lg-0 pt-3 pt-md-5 pb-10 mx-auto" fluid>
    <v-row
      class="contract-title__wrapper mx-auto mb-4 mb-md-6 px-6"
      align="center"
      justify="space-between"
      no-gutters
    >
      <v-col>
        <input
          v-model="contractTitle"
          id="contract-title"
          class="contract-title__view pa-0 ma-0"
          :class="{ 'contract-title__view--error': contractTitleErrorMessage }"
          contenteditable="true"
          hide-details
        />
      </v-col>

      <v-col class="col-auto">
        <v-icon color="#adb5bd" size="36">mdi-pencil-outline</v-icon>
      </v-col>

      <v-col
        v-if="contractTitleErrorMessage"
        class="col-12 error--text text-center font-weight-medium mt-2"
      >
        {{ contractTitleErrorMessage }}
      </v-col>
    </v-row>

    <v-row class="my-0 mb-5" align="center" justify="start" no-gutters>
      <v-col class="col-12 col-md-auto order-4 order-md-1 mb-4 mb-md-0">
        <span class="block-title pr-5">{{ $t('docs') }}</span>
      </v-col>

      <v-col
        class="col-12 col-md-auto order-1 order-md-2 px-3 mb-4 mb-md-0 text-center text-md-left"
      >
        <v-btn
          :color="showAdvancedSettings ? 'primary' : '#424242'"
          :outlined="showAdvancedSettings"
          :text="!showAdvancedSettings"
          @click="showAdvancedSettings = !showAdvancedSettings"
        >
          <v-icon color="primary" left>mdi-cog</v-icon>

          {{ $t('envelop.advanced_settings') }}
        </v-btn>
      </v-col>

      <v-col class="col-12 col-md order-3 order-md-3 px-3 mb-4 mb-md-0 text-center text-md-left">
        <span class="contract-settings__select-language--title">
          {{ $t('contract.create.locale') }}:
        </span>

        <ContractLanguageSelect />
      </v-col>

      <v-col class="col-12 col-md-auto order-5 order-md-4">
        <v-btn color="#424242" text @click="addAttachmentDialog = true">
          <v-icon color="primary" left>mdi-paperclip-plus</v-icon>

          <span class="contract-settings__select-language--title">
            {{ $t('contract.add_attachment') }}
          </span>
        </v-btn>
      </v-col>

      <v-expand-transition>
        <v-col
          v-if="showAdvancedSettings"
          class="col-12 order-2 order-md-5 contract-settings__detail mx-auto py-5 py-mb-10 text-left"
        >
          <v-row align="center" justify="space-between" no-gutters>
            <v-col class="col-12 mb-4 mb-md-6">
              <span class="contract-settings__block-title">
                {{ $t('general.general_title') }}
              </span>
            </v-col>

            <v-col class="col-12 col-md-8 mb-4 mb-md-6">
              <span class="contract-settings__input-title text-uppercase">
                {{ $t('envelope.internal_number') }}:
              </span>

              <v-text-field
                v-model="contractData.number"
                class="contract-settings__input-content"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="col-12 col-md-3 mb-4 mb-md-6">
              <span class="contract-settings__input-title text-uppercase">
                {{ $t('contract.validity_of_proposal') }}:
              </span>

              <v-select
                v-model="contractData.waiting_limit"
                class="contract-settings__input-content"
                :items="[7, 14, 21, 30]"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>

            <v-col class="col-12 mb-4 mb-md-6">
              <span class="contract-settings__input-title text-uppercase">
                {{ $t('contract.url_redirect_after_sign') }}:
              </span>

              <v-text-field
                v-model="contractUrlRedirect"
                class="contract-settings__input-content"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="col-12 mb-4 mt-2 mb-md-6">
              <v-divider></v-divider>
            </v-col>

            <v-col class="col-12 mb-4 mb-md-6">
              <span class="contract-settings__input-title text-uppercase">
                {{ $t('contract.reject.note') }}:
              </span>

              <v-textarea
                v-model="contractNote"
                class="contract-settings__input-content"
                rows="3"
                dense
                outlined
                hide-details
              ></v-textarea>
            </v-col>

            <v-col class="col-12 mb-4 mb-md-6">
              <span class="contract-settings__input-title text-uppercase">
                {{ $t('contract.reject.note_link') }}:
              </span>

              <v-text-field
                v-model="contractNoteLink"
                class="contract-settings__input-content"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="col-12 mb-4 mt-2 mb-md-6">
              <v-divider></v-divider>
            </v-col>

            <v-col class="col-12 mb-4 mb-md-6">
              <span class="contract-settings__block-title">
                {{ $t('contract.notice.title') }}
              </span>
            </v-col>

            <v-col class="col-12 mb-4 mb-md-6">
              <ContractNotices />

              <v-btn color="primary" outlined @click="addContractNotice">
                <v-icon color="primary" left>mdi-plus</v-icon>
                {{ $t('contract.notice.add_notice') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
    </v-row>

    <ContractDocumentStatePreviews :contract-attachments="contractAttachments" />

    <ContractScenarioSettings />

    <ContractSignIdentities />

    <v-row class="mx-auto my-2 mb-6" align="center" justify="center" no-gutters>
      <v-col class="col-12">
        <v-row align="center" justify="start">
          <v-col v-if="canShowAddProposerBtn" class="col-12 col-md-auto">
            <v-menu close-on-click offset-y bottom>
              <template v-slot:activator="{ on, attrs, value }">
                <div class="d-flex create-new-sign-identity__add-button mx-auto">
                  <v-btn color="primary" text @click="addEmptySignIdentity">
                    <v-icon color="primary" left>mdi-plus</v-icon>
                    {{ $t('contract.create.add_signer') }}
                  </v-btn>

                  <v-divider vertical color="#5f23a0"></v-divider>

                  <v-btn icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>{{ `mdi-chevron-${value ? 'up' : 'down'}` }}</v-icon>
                  </v-btn>
                </div>
              </template>

              <v-list dense>
                <v-list-item
                  class="v-btn v-btn--text v-size--default primary--text"
                  link
                  @click="addProposerSignIdentity"
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon color="primary" small>mdi-account-plus-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    {{ $t('contract.create.add_proposer') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col v-else class="col-12 col-md-auto">
            <v-btn color="primary" outlined @click="addEmptySignIdentity">
              <v-icon color="primary" left>mdi-plus</v-icon>
              {{ $t('contract.create.add_signer') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-checkbox
              v-model="hasSequentialSigningOrder"
              class="mt-0"
              :label="$t('sign_identities.depends_on_order')"
              hide-details
              dense
            ></v-checkbox>
          </v-col>

          <v-col v-if="isOneDriveEnabled" class="col-12 col-md-auto">
            <OneDriveFolderSelect />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-4" align="center" justify="end">
      <v-expand-transition>
        <v-col v-if="saveCurrentWorkflowSettings" class="col-12 order-2 order-md-1">
          <PredefinedWorkflowTitleInput v-model="settingsTemplateTitle" />
        </v-col>
      </v-expand-transition>

      <v-col class="col-12 col-md-auto order-1 order-md-2 d-flex align-center">
        <v-checkbox
          v-model="saveCurrentWorkflowSettings"
          class="mt-0 pt-0"
          :label="$t('contract.workflow.predefined.save_header')"
          hide-details
          dense
        ></v-checkbox>
      </v-col>

      <v-col class="col-12 col-md-auto order-3">
        <v-btn
          color="primary"
          :disabled="isDisabledNextStep"
          :loading="isSubmitted"
          @click="onSaveSignIdentitiesAndSettings"
        >
          {{ $t('contract.create.go_to_next') }}
        </v-btn>
      </v-col>
    </v-row>

    <ContractDocumentAddAttachment
      v-if="addAttachmentDialog"
      :multiple-upload="true"
      @close="addAttachmentDialog = false"
    />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import { phoneNumberPreselections } from '@/common/variables/phoneNumbers';
import { useContractStore } from '@/features/contracts/stores/contract.js';
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments';
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices';
import { useFirstStepValidationStore } from '@/features/contracts/stores/firstStepValidation';
import { usePredefinedWorkflowsStore } from '@/features/contracts/stores/predefinedWorkflows';
import { useSignatureFooterSettingsStore } from '@/features/contracts/stores/signatureFooterSettings';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import { useUserStore } from '@/stores/user';
import { useVerificationGroupsStore } from '@/features/contracts/stores/verificationGroups';
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore';
import { useWorkspaceStore } from '@/stores/workspace';
import * as validation from '@/common/reusable/validation';
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker';
import ContractDocumentAddAttachment from '@/features/contracts/components/ContractDocumentAddAttachment';
import ContractDocumentStatePreview from '@/features/contracts/components/ContractDocumentStatePreview';
import ContractDocumentStatePreviews from '@/features/contracts/components/ContractDocumentStatePreviews.vue';
import ContractLanguageSelect from '@/features/contracts/components/ContractLanguageSelect';
import ContractNotices from '@/features/contracts/components/ContractNotices.vue';
import ContractScenarioSettings from '@/features/contracts/components/ContractScenarioSettings.vue';
import ContractSignIdentities from '@/features/contracts/components/ContractSignIdentities.vue';
import LanguageToggle from '@/common/components/LanguageToggle';
import OneDriveFolderSelect from '@/features/contracts/components/OneDriveFolderSelect';
import PageLoader from '@/components/PageLoader.vue';
import PredefinedWorkflowTitleInput from '@/features/contracts/components/PredefinedWorkflowTitleInput.vue';
import { useContractSignTypesStore } from '@/features/contracts/stores/contractSignTypes';

export default defineComponent({
  name: 'ContractsCollectionParties',
  components: {
    ContractDocumentAddAttachment,
    ContractDocumentStatePreview,
    ContractDocumentStatePreviews,
    ContractLanguageSelect,
    ContractNotices,
    ContractScenarioSettings,
    ContractSignIdentities,
    LanguageToggle,
    OneDriveFolderSelect,
    PageLoader,
    PredefinedWorkflowTitleInput,
  },
  setup() {
    const contractStore = useContractStore();
    const contractDocumentsStore = useContractDocumentsStore();
    const contractNoticesStore = useContractNoticesStore();
    const contractSignTypesStore = useContractSignTypesStore();
    const firstStepValidationStore = useFirstStepValidationStore();
    const predefinedWorkflowsStore = usePredefinedWorkflowsStore();
    const signatureFooterSettingsStore = useSignatureFooterSettingsStore();
    const signIdentitiesStore = useSignIdentitiesStore();
    const userStore = useUserStore();
    const verificationGroupsStore = useVerificationGroupsStore();
    const workspaceStore = useWorkspaceStore();
    const templateFieldsStore = useTemplateFieldsStore();

    return {
      contractStore,
      contractDocumentsStore,
      contractNoticesStore,
      contractSignTypesStore,
      firstStepValidationStore,
      predefinedWorkflowsStore,
      signatureFooterSettingsStore,
      signIdentitiesStore,
      userStore,
      verificationGroupsStore,
      workspaceStore,
      templateFieldsStore,
    };
  },
  data() {
    return {
      addAttachmentDialog: false,
      loaded: false,
      contractUrlRedirect: '',
      contractNote: '',
      contractNoteLink: '',
      contractTitleErrorMessage: null,
      showAdvancedSettings: false,
      saveCurrentWorkflowSettings: false,
      settingsTemplateTitle: '',
      submitted: false,
    };
  },
  computed: {
    contractTitle: {
      get() {
        return this.contractStore.title;
      },
      set(value) {
        this.contractStore.data.title = value;
      },
    },
    hasSequentialSigningOrder: {
      get() {
        return this.contractData?.feature_flags?.hasSequentialSigningOrder || false;
      },
      set(value) {
        if (this.loaded) {
          this.contractData.feature_flags.hasSequentialSigningOrder = value;
        }
      },
    },
    canShowAddProposerBtn() {
      return this.signIdentitiesStore.canAddProposerParty;
    },
    contractData() {
      return this.contractStore.contract;
    },
    contractId() {
      return this.$route.params?.contractId;
    },
    contractAttachments() {
      return this.contractStore.attachments;
    },
    contractDocuments() {
      return this.contractDocumentsStore.contractsDocuments;
    },
    isOneDriveEnabled() {
      return this.workspaceStore.workspaceSettings?.onedrive_enabled || false;
    },
    isLoading() {
      return !this.loaded;
    },
    isDisabledNextStep() {
      return this.firstStepValidationStore.errorOccured;
    },
    isSubmitted() {
      return this.submitted;
    },
    workspaceId() {
      return this.$route.params?.workspaceId;
    },
    isIncomplete() {
      return (
        this.signIdentitiesStore.signIdentities?.filter((s) => !s.is_proposer)?.length <
        this.contractStore.minimalCounterPartiesCount
      );
    },
  },
  watch: {
    contractTitle: {
      handler: function (value) {
        this.contractTitleErrorMessage = null;

        if (2 >= value?.length) {
          this.contractTitleErrorMessage = this.$t('general.fill_required2');
        }
      },
    },
    contractUrlRedirect: {
      handler: function (value) {
        this.updateContractUrlRedirect(value);
      },
    },
    contractNote: {
      handler: function (value) {
        this.updateContractNote(value, this.contractNoteLink);
      },
    },
    contractNoteLink: {
      handler: function (value) {
        this.updateContractNote(this.contractNote, value);
      },
    },
  },
  beforeMount() {
    this.contractNoticesStore.resetNotices();
    this.contractStore.resetContractData();
    this.contractSignTypesStore.resetSignTypes();
    this.contractDocumentsStore.resetDocuments();
    this.templateFieldsStore.resetTemplateFieldsStore();
  },
  mounted() {
    this.loaded = false;
    this.signatureFooterSettingsStore.fetchSettings(this.$route.params?.workspaceId);
    this.contractStore
      .fetchContractData(this.contractId, this.workspaceId)
      .then((resp) => {
        if (resp?.note) {
          const lineBreaks = resp.note?.lastIndexOf('\n\n');
          const startBracket = resp.note?.indexOf('(') + 1;
          const endBracket = resp.note?.indexOf(')');

          this.contractNote = resp.note?.substring(0, lineBreaks);
          this.contractNoteLink = resp.note?.substring(startBracket, endBracket);
        }

        this.contractUrlRedirect = resp?.url_redirect;
        this.loaded = true;
      })
      .catch((err) => {
        if (404 === err?.response?.status) {
          this.$notification.error(this.$t('contract.not_found'));

          return this.$router.push({
            name: 'dashboard',
            params: {
              workspace_id: this.$route?.params?.workspaceId,
            },
          });
        }

        this.$notification.error(getErrorResponseMessage(err.response.data));
      });
  },
  methods: {
    addContractNotice() {
      this.contractNoticesStore.addNotice();
    },
    addEmptySignIdentity() {
      this.signIdentitiesStore.addSignIdentity();
    },
    addProposerSignIdentity() {
      this.signIdentitiesStore.addSignIdentity(undefined, true);
    },
    updateContractUrlRedirect(urlRedirect) {
      let currentUrlRedirect = urlRedirect;
      const httpPrefix = 'http://';
      const httpsPrefix = 'https://';

      if (
        currentUrlRedirect !== '' &&
        currentUrlRedirect.substr(0, httpPrefix.length) !== httpPrefix &&
        currentUrlRedirect.substr(0, httpsPrefix.length) !== httpsPrefix
      ) {
        currentUrlRedirect = httpPrefix + currentUrlRedirect;
      }

      this.contractData.url_redirect = currentUrlRedirect;
    },
    updateContractNote(note, noteLink) {
      let currentNoteLink = noteLink;
      const httpPrefix = 'http://';
      const httpsPrefix = 'https://';

      if (
        currentNoteLink !== '' &&
        currentNoteLink.substr(0, httpPrefix.length) !== httpPrefix &&
        currentNoteLink.substr(0, httpsPrefix.length) !== httpsPrefix
      ) {
        currentNoteLink = httpPrefix + currentNoteLink;
      }

      this.contractData.note =
        `${note ? (currentNoteLink ? `${note}\n\n` : `${note}`) : ''}${
          currentNoteLink ? `[${this.$t('general.open_attached_link')}](${currentNoteLink})` : ''
        }` || null;
    },
    onSaveSignIdentitiesAndSettings() {
      if (this.isIncomplete) {
        return this.$notification.error(this.$t('contract.create.error.parties_empty'));
      }

      if (this.firstStepValidationStore.errorOccured) {
        return this.$notification.error(this.$t('general.fill_required'));
      }

      const data = Object.assign({}, this.contractData, {
        one_device: this.contractData?.one_device ? 1 : 0,
        parties: this.signIdentitiesStore.signIdentities?.map(({ mobile, ...data }) => {
          if (phoneNumberPreselections?.find((p) => p.preselection === mobile)) {
            return data;
          }

          if (mobile && !validation.isPhoneNumberValid(mobile)) {
            return {
              mobile: `${
                phoneNumberPreselections?.find(
                  (p) => p.country === this.userStore.userInvoiceInfo.invoice_country,
                )?.preselection || '+420'
              }${mobile}`,
              ...data,
            };
          }

          return { mobile, ...data };
        }),
      });

      this.submitted = true;
      this.contractStore
        .updateContractData({
          contractId: this.contractAttachments
            ? this.contractAttachments.find((contract) => !contract.is_attachment)?.id
            : this.contractId,
          data,
        })
        .then(async () => {
          if (this.saveCurrentWorkflowSettings) {
            await this.predefinedWorkflowsStore.createNewSettingsTemplate({
              workspaceId: this.$route.params.workspaceId,
              data: {
                name: this.settingsTemplateTitle,
                contractId: this.contractId,
              },
            });
          }

          await this.signIdentitiesStore.fetchSignIdentities(this.contractId);

          if (FeatureFlagsChecker.hasEnabledSigniAuthentication(this.contractStore.data)) {
            await this.verificationGroupsStore.resetUsedGroupsForSignIdentities(this.contractId);
            await this.verificationGroupsStore.saveUsedGroupsForSignIdentities(this.contractId);
          }

          return this.$router.push({
            name: 'createContent',
            params: {
              contract_id: this.$route.params?.contractId,
              workspace_id: this.$route.params?.workspaceId,
            },
          });
        })
        .catch((err) => {
          if (err?.response?.data?.message?.includes('User cannot sign')) {
            const startIndex = err?.response?.data?.message?.indexOf('[') + 1;
            const endIndex = err?.response?.data?.message?.indexOf(']', startIndex);
            const user = err?.response?.data?.message?.substring(startIndex, endIndex);

            this.$notification.error(
              this.$t('contract.create.error.parties_no_right_to_sign', { user }),
            );
          } else {
            this.$notification.error(getErrorResponseMessage(err?.response?.data));
          }
        })
        .finally(() => {
          this.submitted = false;
        });

      this.sendNotices();
    },
    sendNotices() {
      const signIdentitiesEmails = this.signIdentitiesStore?.list?.map(
        (signIdentity) => signIdentity?.email,
      );

      return this.contractNoticesStore.createContractNotices(
        this.contractId,
        this.contractNoticesStore?.notices?.map((notice) => ({
          emailNotificationType: notice?.emailNotificationType,
          recipients: 'all' === notice?.recipients[0] ? signIdentitiesEmails : notice?.recipients,
          deliverAt: notice?.deliverAt,
        })),
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.create-new-sign-identity__add-button {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #5f23a0;
  width: fit-content;
}

.contract-title {
  &__wrapper {
    max-width: 35rem;
    width: 100%;
  }

  &__view {
    width: 100%;
    min-height: 37px;
    color: #424242;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 37px;
    margin-bottom: 2px !important;

    &:focus {
      outline: none;
    }

    &:hover {
      border-bottom: 2px solid #424242;
      margin-bottom: 0 !important;
    }
  }

  &__view--error {
    color: red;
    border-bottom: 2px solid red;
    margin-bottom: 0 !important;
  }
}

.block-title {
  height: 28px;
  color: #424242;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27.5px;
}

.contract-settings {
  &__activators {
    max-width: 520px;
    width: 100%;
  }

  &__select-language--title {
    color: #424242;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }

  &__detail {
    max-width: 730px;
    width: 100%;
  }

  &__block-title {
    color: #424242;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27.5px;
  }

  &__input-title {
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }

  &__input-content {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
