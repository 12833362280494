<template>
  <!--  TODO - Refactoring required!!! -->

  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-row
        class="one-drive__activator px-4"
        align="center"
        justify="space-between"
        no-gutters
        v-bind="attrs"
        v-on="on"
      >
        <v-col class="col-auto">
          <v-img class="one-drive__activator--image" :src="oneDriveLogo" contain />
        </v-col>
      </v-row>
    </template>

    <v-card v-if="!!onedrivePath" class="pa-5 text-center">
      <v-card v-if="!onedriveFolder" flat>
        <v-card-text v-if="!isOwner">
          {{ $t('workspace.drive.onedrive_unset') }}
        </v-card-text>

        <v-card-text>
          <p>{{ $t('workspace.drive.onedrive_finish-setting') }}</p>
          <v-btn color="primary" @click="loadWorkspaceSetting"> {{ $t('general.settings') }}</v-btn>
        </v-card-text>
      </v-card>

      <div
        v-else
        class="onedrive-explorer mx-auto content-finish__box-one-drive"
        @click="chooseOnedriveFolder = true"
      >
        <img :src="oneDriveLogo" alt="onedrive logo" class="content-finish__box-one-drive-logo" />
        {{ $t('contract.onedrive.select_folder') }}:<br />

        <div class="d-flex align-center justify-space-between">
          <span class="content-finish__box-one-drive-name">
            {{ onedrivePath }}
          </span>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span> {{ $t('contract.onedrive.help') }}</span>
          </v-tooltip>
        </div>
      </div>

      <v-card-actions>
        <v-btn color="primary" class="mx-auto" text @click="dialog = false">
          {{ $t('general.just_close') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <FolderStructureExplorer
      v-if="chooseOnedriveFolder"
      v-model="chooseOnedriveFolder"
      :loadFunction="onedriveDataLoad"
      @selectFolder="selectOnedriveFolder($event)"
      @cancel="chooseOnedriveFolder = false"
    />
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useWorkspaceStore } from '@/stores/workspace';
import * as sharePintService from '@/features/contracts/services/sharePointService';
import FolderStructureExplorer from '@/views/create/components/FolderStructureExplorer';

export default defineComponent({
  name: 'OneDriveFolderSelect',
  components: { FolderStructureExplorer },
  setup() {
    const contractStore = useContractStore();
    const workspaceStore = useWorkspaceStore();

    return {
      contractStore,
      workspaceStore,
    };
  },
  data() {
    return {
      dialog: false,
      loadingSharePointData: false,
      oneDriveSettings: null,
      onedriveFolder: '',
      chooseOnedriveFolder: false,
    };
  },
  computed: {
    oneDriveLogo() {
      return require(`@/features/contracts/assets/onedrive_logo.svg`);
    },
    onedrivePath() {
      return this.onedriveFolder && this.onedriveFolder !== '/'
        ? this.onedriveFolder
        : this.$t('contract.onedrive.root');
    },
    isOwner() {
      return this.workspaceStore.isOwner;
    },
  },
  watch: {
    onedriveFolder: {
      handler: function (value) {
        if (value) {
          this.contractStore.data.onedrive_folder = value;
        }
      },
    },
  },
  mounted() {
    this.loadingSharePointData = true;

    sharePintService.fetchSharePointSettings(this.$route.params?.workspaceId).then((resp) => {
      this.oneDriveSettings = resp;
      this.onedriveFolder = resp?.path;
      this.contractStore.data.onedrive_folder = resp?.path;
      this.loadingSharePointData = false;
    });
  },
  methods: {
    loadWorkspaceSetting() {
      return this.$router.push({
        name: 'workspaceSettings',
        params: {
          workspace_id: this.$route.params.workspaceId,
        },
        hash: '#settings',
      });
    },
    onedriveDataLoad(path = null) {
      return sharePintService.fetchSharePointDirectories({
        workspaceId: this.$route.params.workspaceId,
        path,
      });
    },
    selectOnedriveFolder(folder) {
      this.onedriveFolder = folder;
      this.chooseOnedriveFolder = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.one-drive {
  &__activator {
    box-sizing: border-box;
    height: 36px;
    width: fit-content;
    border: 1px solid #094ab1;
    border-radius: 5px;

    &--image {
      height: 14px;
      width: 83.47px;
    }
  }
}
</style>
