import { internalApiRequest } from '@/shared/services/apiService';

/**
 * @param {String|Number} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchWorkspaceTemplates = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/templates?workspaceId=${workspaceId}`,
  });
};
