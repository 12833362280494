<template>
  <div class="search-input__wrapper">
    <v-menu
      v-model="show"
      :value="hasResults"
      transition="slide-y-transition"
      offset-x
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="inputValue"
          class="search-input__input"
          :rules="rules"
          dense
          hide-details="auto"
          validate-on-blur
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-list
        v-if="hasResults || isProposerParty"
        class="search-input__options-menu overflow-y-auto"
        dense
      >
        <v-list-item
          v-for="(item, index) in availableResults"
          :key="index"
          class="search-input__options-menu-content"
          link
          @click="$emit('on-update-sign-identity', item)"
        >
          <v-list-item-content>
            <v-list-item-title class="search-input__options-menu-content--name">
              {{ getCounterpartyNamePreview(item) }}
            </v-list-item-title>

            <v-list-item-subtitle class="search-input__options-menu-content--email">
              {{ item.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-if="!isProposerParty"
          class="search-input__options-menu-content"
          link
          @click="$emit('on-create-sign-identity')"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center justify-start">
              <v-icon color="#95AFDA">mdi-plus</v-icon>

              <span class="search-input__options-menu-content--name">
                {{ $t('contract.search.create_link') }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-else class="search-input__options-menu d-flex align-center justify-center pa-3">
        <span class="search-input__options-menu-content--name">
          {{ $t('contract.typing.signer') }}
        </span>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import { fetchCounterParties } from '@/features/contracts/services/contractService';

export default defineComponent({
  name: 'SignIdentitySearchInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    isProposerParty: {},
    rules: {
      type: Array,
    },
  },
  setup() {
    const contractStore = useContractStore();
    const signIdentitiesStore = useSignIdentitiesStore();

    return {
      contractStore,
      signIdentitiesStore,
    };
  },
  data() {
    return {
      show: false,
      debounceTimeout: 0,
      isLoadingSearchOptionsFromApi: false,
      results: [],
      baseResults: [],
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    availableResults() {
      if (this.isProposerParty) {
        return this.availableProposers;
      }

      return this.inputValue ? this.results : this.baseResults;
    },
    availableProposers() {
      const allProposers = this.contractStore.availableProposers?.filter(
        (p) => !this.signIdentitiesStore.signIdentities?.find((s) => p.email === s.email),
      );

      if (this.inputValue) {
        return allProposers
          ?.filter(
            (p) =>
              p.email?.includes(this.inputValue?.toLowerCase()) ||
              `${p.firstname} ${p.lastname}`
                .toLowerCase()
                ?.includes(this.inputValue?.toLowerCase()),
          )
          ?.splice(0, 4);
      }

      return allProposers?.splice(0, 4);
    },
    hasResults() {
      return this.results?.length || this.baseResults?.length;
    },
  },
  watch: {
    inputValue: {
      handler: function (value) {
        if (!value) {
          this.results = [];

          return;
        }

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          clearTimeout(this.debounceTimeout);
          this.fetchCounterParties(value);
          this.debounceTimeout = false;
        }, 500);
      },
    },
  },
  mounted() {
    fetchCounterParties({
      contractId: this.$route.params?.contractId,
      searchTerm: this.inputValue,
    }).then((resp) => {
      this.baseResults = resp
        ?.filter((p) => !this.signIdentitiesStore.signIdentities?.find((s) => s.email === p.email))
        ?.slice(0, 5)
        ?.map((p) => {
          if (null === p?.party_order) {
            delete p.party_order;
          }

          return p;
        });
    });
  },
  methods: {
    async fetchCounterParties() {
      this.isLoadingSearchOptionsFromApi = true;

      fetchCounterParties({
        contractId: this.$route.params?.contractId,
        searchTerm: this.inputValue,
      })
        .then((resp) => {
          this.results = resp
            ?.filter(
              (p) => !this.signIdentitiesStore.signIdentities?.find((s) => s.email === p.email),
            )
            ?.slice(0, 5)
            ?.map((p) => {
              if (null === p?.party_order) {
                delete p.party_order;
              }

              return p;
            });
          this.show = true;
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.create.error.parties_load'));
        })
        .finally(() => {
          this.isLoadingSearchOptionsFromApi = false;
        });
    },
    getCounterpartyNamePreview(counterParty) {
      return `${counterParty?.firstname || ''} ${counterParty?.lastname || ''}`?.trim();
    },
  },
});
</script>

<style lang="scss" scoped>
.search-input {
  &__wrapper {
    position: relative;
  }

  &__input {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;

    &--disabled {
      opacity: 0.5;
      color: #868e96;
    }
  }

  &__options-menu {
    max-width: 300px;
    width: 100%;
    border: 1px solid #7031b4;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &__options-menu-content {
    &--name {
      color: #424242;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17.5px;
    }

    &--email {
      color: #868e96;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
}
</style>
