import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { authorizedApiRequest } from '@/services/ApiService';
import { useContractStore } from '@/features/contracts/stores/contract';
import Vue from 'vue';

export const useContractNoticesStore = defineStore('contractNotices', () => {
  const list = ref([]);

  const notices = computed(() => list.value);

  const contractStore = useContractStore();

  async function fetchContractNotices(contractId) {
    await authorizedApiRequest({
      method: 'GET',
      endpoint: `/api/v1/contracts/${contractId}/email-notification`,
    }).then((resp) => {
      if (resp.length > 0) {
        list.value = resp.map((notice) => {
          let diffInDays;

          if ('after_send' === notice?.notificationEmailEnum) {
            const today = new Date().setHours(0, 0, 0, 0);
            const deliverAt = new Date(notice?.deliverAt?.date).setHours(0, 0, 0, 0);

            const diffTime = deliverAt - today;
            diffInDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          }

          if ('before_sign_expiration' === notice?.notificationEmailEnum) {
            const today = new Date().setHours(0, 0, 0, 0);
            const deliverAt = new Date(notice?.deliverAt?.date).setHours(0, 0, 0, 0);

            const diffTime = deliverAt - today;
            diffInDays =
              contractStore?.contract?.waiting_limit - Math.floor(diffTime / (1000 * 60 * 60 * 24));
          }

          const noticeTime = {
            value: diffInDays,
          };

          return {
            emailNotificationType: notice?.notificationEmailEnum,
            recipients: notice?.recipients?.length > 1 ? ['all'] : notice?.recipients,
            deliverAt: notice?.deliverAt?.date,
            isEditing: false,
            noticeTime: noticeTime,
          };
        });
      } else {
        const noticeData = {
          emailNotificationType: 'before_sign_expiration',
          recipients: ['all'],
          noticeTime: {
            value: 1,
          },
          isEditing: false,
        };

        const today = new Date();

        if (contractStore?.contract?.waiting_limit < 2) {
          today.setHours(23, 59, 0, 0);
        } else {
          today.setDate(today.getDate() + contractStore?.contract?.waiting_limit - 1);
        }

        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        const hours = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');
        const seconds = today.getSeconds().toString().padStart(2, '0');

        noticeData.deliverAt = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        list.value.push(noticeData);
      }
    });
  }

  function createContractNotices(contractId, data) {
    return authorizedApiRequest({
      method: 'POST',
      endpoint: `/api/v1/contracts/${contractId}/email-notification`,
      data,
    });
  }

  function updateNotices(filteredNotices) {
    list.value = filteredNotices;
  }

  function resetNotices() {
    list.value = [];
  }

  function addNotice() {
    list.value.push({
      emailNotificationType: 'after_send',
      recipients: [],
      deliverAt: null,
      isEditing: true,
    });
  }

  function editNotice(index) {
    list.value[index].isEditing = true;
  }

  function removeNotice(index) {
    list.value?.splice(index, 1);
  }

  function saveNotice(index, data) {
    Vue.set(list.value, index, data);
  }

  return {
    notices,
    fetchContractNotices,
    createContractNotices,
    updateNotices,
    resetNotices,
    addNotice,
    editNotice,
    removeNotice,
    saveNotice,
  };
});
