import { defineStore } from 'pinia';

export const useContractSettingsStore = defineStore('contractSettings', {
  state: () => ({
    convertDocuments: true,
  }),
  actions: {
    setPdfAConverting(value) {
      this.convertDocuments = value;
    },
  },
  getters: {
    convertDocumentsToPdfA: (state) => {
      return state.convertDocuments;
    },
  },
});
