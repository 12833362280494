export const bankIdCompaniesLogos = [
  {
    name: 'Logo Air Bank',
    image: require('@/assets/logos/air_bank_logo.png'),
  },
  {
    name: 'Logo Česká spořitelna',
    image: require('@/assets/logos/csas_logo.png'),
  },
  {
    name: 'Logo ČSOB',
    image: require('@/assets/logos/csob_logo.png'),
  },
  {
    name: 'Logo Komerční banky',
    image: require('@/assets/logos/komercni_banka_logo.png'),
  },
  {
    name: 'Logo Moneta',
    image: require('@/assets/logos/moneta_logo.png'),
  },
  {
    name: 'Logo Raiffeissen Bank',
    image: require('@/assets/logos/logo-rb-cz-nahled.gif'),
  },
];
