<template>
  <v-row class="mx-auto my-0 my-5" align="center" justify="center" no-gutters>
    <v-col class="col-12 col-md-auto">
      <span class="parties-title pr-5">{{ $t('envelope.recipients') }}</span>
    </v-col>

    <v-col class="col-12 col-md">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            :disabled="isPredefinedWorkflowDisabled"
            v-on="on"
            v-bind="attrs"
            text
          >
            <v-icon left>mdi-note-edit</v-icon>
            {{ $t('envelope.signature_scenario_select') }}
          </v-btn>
        </template>

        <v-list class="options-menu" dense>
          <v-list-item
            v-for="predefinedWorkflow in predefinedWorkflows"
            :key="predefinedWorkflow.id"
            class="options-menu__content--label px-4"
            link
          >
            <v-list-item-title @click="onUsePredefinedWorkflow(predefinedWorkflow)">
              <span class="options-menu__content--label">{{ predefinedWorkflow.name }}</span>
            </v-list-item-title>

            <v-list-item-action>
              <v-btn
                color="primary"
                :loading="predefinedWorkflow.id === deletingWorkflow"
                small
                icon
                @click="onDeletePredefinedWorkflow(predefinedWorkflow)"
              >
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col class="col-12 col-md-auto">
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn color="primary" v-on="on" v-bind="attrs" text>
            {{ $t('envelope.signature_scenario_settings') }}
            <v-icon color="primary" class="mr-0">
              {{ `mdi-chevron-${value ? 'up' : 'down'}` }}
            </v-icon>
          </v-btn>
        </template>

        <v-list class="options-menu" dense>
          <v-list-item class="px-0" link>
            <v-checkbox v-model="enableFindAnchors" class="my-1 px-2" hide-details dense>
              <template v-slot:label>
                <span class="options-menu__content--label">
                  {{ $t('envelope.preserve_positions') }}
                </span>
              </template>
            </v-checkbox>
          </v-list-item>

          <v-list-item v-if="oneDeviceEnabled" class="px-0" link>
            <v-checkbox v-model="oneDevice" class="my-1 px-2" hide-details dense>
              <template v-slot:label>
                <span class="options-menu__content--label">
                  {{ $t('contract.one_device_sign') }}
                </span>
              </template>
            </v-checkbox>
          </v-list-item>

          <v-list-item class="px-0" link>
            <v-checkbox v-model="lockAfterSeal" class="my-1 px-2" hide-details dense>
              <template v-slot:label>
                <span class="options-menu__content--label">
                  {{ $t('envelop.lock_after_seal') }}
                </span>
              </template>
            </v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useContractSettingsStore } from '@/features/contracts/stores/contractSettings';
import { usePredefinedWorkflowsStore } from '@/features/contracts/stores/predefinedWorkflows';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import { useWorkspaceStore } from '@/stores/workspace';
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker';

export default defineComponent({
  name: 'ContractScenarioSettings',
  setup() {
    const contractStore = useContractStore();
    const contractSettingsStore = useContractSettingsStore();
    const predefinedWorkflowStore = usePredefinedWorkflowsStore();
    const signIdentitiesStore = useSignIdentitiesStore();
    const workspaceStore = useWorkspaceStore();

    return {
      contractStore,
      contractSettingsStore,
      predefinedWorkflowStore,
      signIdentitiesStore,
      workspaceStore,
    };
  },
  data() {
    return {
      deletingWorkflow: null,
    };
  },
  computed: {
    enableFindAnchors: {
      get() {
        return this.contractData?.feature_flags?.enabledFindAnchors || false;
      },
      set(val) {
        this.contractData.feature_flags.enabledFindAnchors = val;
      },
    },
    oneDevice: {
      get() {
        return (
          this.contractData.sign_identities.some((identity) => identity.one_device_group) ||
          this.contractData.one_device ||
          false
        );
      },
      set(value) {
        this.contractData.one_device = value;
      },
    },
    lockAfterSeal: {
      get() {
        return this.contractData?.lockAfterSeal || false;
      },
      set(value) {
        this.contractData.lockAfterSeal = value;
      },
    },
    contractData() {
      return this.contractStore.contract;
    },
    predefinedWorkflows() {
      return this.predefinedWorkflowStore.predefinedWorkflowsList;
    },
    isPredefinedWorkflowDisabled() {
      return 0 === this.predefinedWorkflows?.length;
    },
    oneDeviceEnabled() {
      return FeatureFlagsChecker.oneDeviceEnabled(this.contractStore.data);
    },
  },
  beforeMount() {
    this.predefinedWorkflowStore.resetTemplatesList();
  },
  mounted() {
    this.predefinedWorkflowStore.fetchSettingsTemplatesList(this.$route.params?.workspaceId);
    this.contractSettingsStore.setPdfAConverting(
      this.workspaceStore.workspaceSettings?.convertToPdfA || false,
    );
  },
  methods: {
    onUsePredefinedWorkflow(selectedWorkflow) {
      this.signIdentitiesStore.list = selectedWorkflow.sign_identities;
      this.contractStore.data = Object.assign(this.contractStore.contract, {
        feature_flags: {
          ...this.contractStore.contract?.feature_flags,
          hasSequentialSigningOrder: selectedWorkflow?.sequential_signing_order,
        },
        url_redirect: selectedWorkflow?.url_redirect,
        one_device: selectedWorkflow.sign_identities.some((identity) => identity.one_device_group),
        enabled_find_anchors: selectedWorkflow?.enabled_find_anchors,
      });
    },
    onDeletePredefinedWorkflow(selectedWorkflow) {
      this.deletingWorkflow = selectedWorkflow.id;
      this.predefinedWorkflowStore
        .deleteSettingsTemplate({
          templateId: selectedWorkflow.id,
          workspaceId: this.$route.params?.workspaceId,
        })
        .finally(() => {
          this.deletingWorkflow = null;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.parties-title {
  height: 28px;
  color: #424242;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27.5px;
}

.options-menu {
  width: 100%;
  border: 1px solid #7031b4;
  border-radius: 4px;
  background-color: #ffffff;

  &__content--label {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
