<template>
  <v-row
    class="document-state-preview__wrapper pa-2 mb-3"
    :class="{
      'document-state-preview__wrapper--error': isFailed,
    }"
    align="center"
    justify="space-between"
    no-gutters
  >
    <v-col>
      <v-row align="center" justify="start">
        <v-col class="col-auto">
          <img class="pa-2 pb-0" src="/images/try-sign.png" width="auto" height="50px" alt="" />
        </v-col>

        <v-col v-if="isLoading">
          <v-skeleton-loader type="list-item-two-line" class="ma-0 pa-0"></v-skeleton-loader>
        </v-col>

        <v-col v-else>
          <span>{{ contractDocumentTitle }}</span>
          <div v-if="isFailed" class="subtitle-2 error--text">{{ getErrorReport() }}</div>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="!isLoading" class="col-auto">
      <v-menu rounded="md" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list class="options-menu">
          <v-list-item v-if="isDocType" class="px-0" link @click="replaceAttachmentDialog = true">
            <v-list-item-content>
              <v-row align="center" justify="center" no-gutters>
                <v-col class="col-auto pl-4 pr-2">
                  <v-icon color="#95afda" class="options-menu__content--icon" dense>
                    mdi-replay
                  </v-icon>
                </v-col>

                <v-col class="options-menu__content--text pr-4">
                  {{ $t('upload.retry') }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="px-0" link @click="downloadPdf">
            <v-list-item-content>
              <v-row align="center" justify="center" no-gutters>
                <v-col class="col-auto pl-4 pr-2">
                  <v-icon color="#95afda" class="options-menu__content--icon" dense>
                    mdi-text-box-multiple-outline
                  </v-icon>
                </v-col>

                <v-col class="options-menu__content--text pr-4">
                  {{ $t('document.download_pdf') }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="menu-divider"></v-divider>

          <v-list-item class="px-0" link @click="showDeleteDocumentDialog = true">
            <v-list-item-content>
              <v-row align="center" justify="center" no-gutters>
                <v-col class="col-auto pl-4 pr-2">
                  <v-icon color="#95afda" class="options-menu__content--icon" dense>
                    mdi-trash-can-outline
                  </v-icon>
                </v-col>

                <v-col class="options-menu__content--text pr-4">
                  {{ $t('general.remove') }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <ContractDocumentAddAttachment
      v-if="replaceAttachmentDialog"
      :add-new-attachment="false"
      :replace-contract-id="contractAttachment.id"
      @close="onReplaceDocument"
    />

    <SConfirmDialog
      v-model="showDeleteDocumentDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteDocument"
    />
  </v-row>
</template>

<script>
import download from 'downloadjs';
import { defineComponent } from 'vue';
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments';
import { downloadPdfFile } from '@/features/contracts/services/documentService';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import ContractDocumentAddAttachment from '@/features/contracts/components/ContractDocumentAddAttachment';
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default defineComponent({
  name: 'ContractDocumentStatePreview',
  components: {
    ContractDocumentAddAttachment,
    SConfirmDialog,
  },
  props: {
    contractAttachment: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const contractDocumentsStore = useContractDocumentsStore();

    return {
      contractDocumentsStore,
    };
  },
  data() {
    return {
      deleting: false,
      loaded: false,
      replaceAttachmentDialog: false,
      showDeleteDocumentDialog: false,
    };
  },
  computed: {
    isDocType() {
      return 'doc' === this.contractAttachment?.type;
    },
    contractDocumentTitle() {
      return this.documentData?.data?.file_name || this.contractAttachment?.title;
    },
    documentData() {
      return this.contractDocumentsStore.documentData(this.contractAttachment?.document?.id);
    },
    isLoading() {
      return !this.loaded || this.deleting;
    },
    isFailed() {
      return !!this.documentData?.data?.error || !!this.documentData?.signTypes?.error;
    },
  },
  mounted() {
    this.loaded = false;
    this.contractDocumentsStore
      .loadDocumentData({
        contractId: this.contractAttachment?.id,
        documentId: this.contractAttachment?.document?.id,
        type: this.contractAttachment?.type,
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  methods: {
    downloadPdf() {
      downloadPdfFile(this.contractAttachment?.id).then((resp) => {
        download(
          new Blob([resp], { type: 'application/pdf' }),
          `${this.contractAttachment.title}.pdf`,
          'application/pdf',
        );
      });
    },
    deleteDocument() {
      this.deleting = true;
      this.contractDocumentsStore
        .eraseDocument(this.contractAttachment?.id)
        .then(() => {
          this.$notification.success(this.$t('docs.delete.ok'));
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.cannot_be_deleted'));
        });
    },
    getErrorReport() {
      return getErrorResponseMessage(this.documentData?.data?.error);
    },
    onReplaceDocument() {
      this.loaded = false;
      this.replaceAttachmentDialog = false;
      this.contractDocumentsStore
        .loadDocumentData({
          contractId: this.contractAttachment?.id,
          documentId: this.contractAttachment?.document?.id,
          type: this.contractAttachment?.type,
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.document-state-preview {
  &__wrapper {
    min-height: 74px;
    width: 100%;
    border: 1px solid #d4dff0;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;

    &--error {
      background-color: #ffe2e3;
    }

    @media only screen and (max-width: 960px) {
      min-height: 57px;
    }
  }
}

.options-menu {
  border: 1px solid #7031b4;
  border-radius: 4px;
  background-color: #ffffff;

  &__content--text {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__content--icon {
    height: 14.95px;
    width: 13px;
  }
}

.menu-divider {
  height: 1px;
  border-color: #d2ddee;
}
</style>
