<template>
  <v-row class="mx-auto my-0 mb-4" align="center" justify="center" no-gutters>
    <drop-list :items="signIdentities" @reorder="$event.apply(signIdentitiesStore.list)">
      <template v-slot:item="{ item, index }">
        <drag class="col-12 pa-0" :disabled="isDisabledDragging" :key="index" :data="item">
          <ContractSignIdentity v-model="signIdentities[index]" :sign-identity-index="index" />
        </drag>
      </template>
    </drop-list>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import { Drag, DropList } from 'vue-easy-dnd';
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments';
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices';
import { useContractStore } from '@/features/contracts/stores/contract';
import { useContractSignTypesStore } from '@/features/contracts/stores/contractSignTypes';
import { useCurrentSignIdentityStore } from '@/features/contracts/stores/currentSignIdentity';
import { useFirstStepValidationStore } from '@/features/contracts/stores/firstStepValidation';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import { useSignIdentityProposerRolesStore } from '@/features/contracts/stores/signIdentityProposerRoles';
import { useVerificationGroupsStore } from '@/features/contracts/stores/verificationGroups';
import { useWorkspaceStore } from '@/stores/workspace';
import FeatureFlagsChecker from '@/common/reusable/featureFlagsChecker';
import ContractSignIdentity from '@/features/contracts/components/ContractSignIdentity.vue';

export default defineComponent({
  name: 'ContractSignIdentities',
  components: {
    ContractSignIdentity,
    Drag,
    DropList,
  },
  setup() {
    const contractDocumentsStore = useContractDocumentsStore();
    const contractNoticesStore = useContractNoticesStore();
    const contractStore = useContractStore();
    const contractSignTypesStore = useContractSignTypesStore();
    const currentSignIdentityStore = useCurrentSignIdentityStore();
    const firstStepValidationStore = useFirstStepValidationStore();
    const signIdentitiesStore = useSignIdentitiesStore();
    const signIdentityProposerRoles = useSignIdentityProposerRolesStore();
    const verificationGroupStore = useVerificationGroupsStore();
    const workspaceStore = useWorkspaceStore();

    return {
      contractDocumentsStore,
      contractNoticesStore,
      contractStore,
      contractSignTypesStore,
      currentSignIdentityStore,
      firstStepValidationStore,
      signIdentitiesStore,
      signIdentityProposerRoles,
      verificationGroupStore,
      workspaceStore,
    };
  },
  data() {
    return {
      loadedSignTypes: true,
    };
  },
  computed: {
    contractId() {
      return this.$route.params?.contractId;
    },
    isDisabledDragging() {
      return !this.contractStore.hasSequentialSigningOrder;
    },
    isLoadingDocuments() {
      return !this.contractDocumentsStore.isEveryDocumentLoaded;
    },
    signIdentities() {
      return this.signIdentitiesStore?.list || [];
    },
  },
  watch: {
    isLoadingDocuments: {
      handler: async function (value) {
        if (false === value) {
          this.contractSignTypesStore.fetchAvailableSignTypes().finally(() => {
            this.loadedSignTypes = true;
          });
        } else {
          this.loadedSignTypes = false;
        }
      },
    },
  },
  beforeMount() {
    this.signIdentitiesStore.resetSignIdentities();
  },
  mounted() {
    if (!this.isLoadingDocuments) {
      this.loadedSignTypes = false;
      this.contractSignTypesStore.fetchAvailableSignTypes().finally(() => {
        this.loadedSignTypes = true;
      });
    }

    if ('legal' === this.workspaceStore.workspaceType) {
      this.signIdentityProposerRoles.fetchProposerRoles(this.$route.params?.workspaceId);
    }

    if (FeatureFlagsChecker.hasEnabledSigniAuthentication(this.contractStore.data)) {
      this.verificationGroupStore.fetchPredefinedVerificationGroups(this.$route.params.workspaceId);
    }

    this.signIdentitiesStore.fetchSignIdentities(this.contractId).then(() => {
      this.contractNoticesStore.fetchContractNotices(this.contractId);
    });
  },
  beforeDestroy() {
    this.signIdentitiesStore.resetSignIdentities();
  },
});
</script>

<style lang="scss" scoped>
.skeleton-loader__sign-identity {
  min-height: 155px;
  border: 1px solid #d4dff0;
  border-radius: 5px;
}
</style>
