import { defineStore } from 'pinia';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';
import * as verificationGroupsService from '@/features/contracts/services/verificationGroupsService';

const findDocsPersonIdByEmail = (signIdentityEmail) => {
  const signIdentitiesStore = useSignIdentitiesStore();

  return signIdentitiesStore.docsPersonIdBySignIdentityEmail(signIdentityEmail);
};

export const useVerificationGroupsStore = defineStore('verificationGroups', {
  state: () => ({
    predefinedGroups: [],
  }),
  actions: {
    async fetchPredefinedVerificationGroups(workspaceId) {
      this.predefinedGroups = [];

      await verificationGroupsService
        .fetchVerificationGroupsList(workspaceId)
        .then(async (resp) => {
          const verificationTypes = await verificationGroupsService.fetchVerificationTypes();

          for (const group of resp) {
            verificationGroupsService
              .fetchVerificationGroup({ workspaceId, groupId: group?.id })
              .then((resp) => {
                this.predefinedGroups.push({
                  ...group,
                  signIdentities: [],
                  verifications: resp?.map((groupItem) => {
                    return {
                      ...groupItem,
                      type: verificationTypes?.find((type) => type?.id === groupItem?.authTypeId),
                    };
                  }),
                });
              });
          }
        });
    },
    updateSignIdentitiesListOfGroup(signIdentityEmail, groupIds) {
      this.predefinedGroups?.forEach((group) => {
        if (groupIds?.includes(group?.id) && !group?.signIdentities?.includes(signIdentityEmail)) {
          group.signIdentities.push(signIdentityEmail);
        }

        if (
          !groupIds?.includes(group?.id) &&
          group?.signIdentities.indexOf(signIdentityEmail) > -1
        ) {
          group?.signIdentities.splice(group?.signIdentities.indexOf(signIdentityEmail), 1);
        }
      });
    },
    async loadSignIdentityListOfGroups(contractId, signIdentityEmail) {
      const docsPersonId = findDocsPersonIdByEmail(signIdentityEmail);

      if (!docsPersonId) {
        return;
      }

      let usedPredefinedGroupsForSignIdentity;

      try {
        usedPredefinedGroupsForSignIdentity = await verificationGroupsService.fetchDocsPersonGroups(
          {
            contractId,
            docsPersonId,
          },
        );
      } catch (e) {
        usedPredefinedGroupsForSignIdentity = [];
      }

      this.updateSignIdentitiesListOfGroup(
        signIdentityEmail,
        usedPredefinedGroupsForSignIdentity?.map((group) => {
          return group.id;
        }),
      );
    },
    async saveUsedGroupsForSignIdentities(contractId) {
      for (const group of this.predefinedGroups) {
        if (group.signIdentities?.length) {
          await verificationGroupsService.saveDocsPersonVerificationGroup({
            contractId,
            groupId: group?.id,
            data: {
              docsPersonIds: group.signIdentities?.map((signIdentityEmail) =>
                findDocsPersonIdByEmail(signIdentityEmail),
              ),
            },
          });
        }
      }
    },
    async resetUsedGroupsForSignIdentities(contractId) {
      const signIdentityStore = useSignIdentitiesStore();

      for (const group of this.predefinedGroups) {
        await verificationGroupsService.resetDocsPersonVerificationGroup({
          contractId,
          groupId: group?.id,
          data: {
            docsPersonIds: signIdentityStore.docsPersonsIdsFromList,
          },
        });
      }
    },
  },
  getters: {
    predefinedVerificationGroups: (state) => {
      return state.predefinedGroups?.map((group) => ({
        id: group.id,
        name: group.name,
      }));
    },
    verificationGroupsIdsBySignIdentityEmail: (state) => (signIdentityEmail) => {
      return state.predefinedGroups
        ?.filter((group) => {
          return group?.signIdentities?.includes(signIdentityEmail);
        })
        ?.map((group) => group.id);
    },
    verificationGroupPrice: (state) => (groupId) => {
      let finalPrice = 0;

      for (const verificationType of state.predefinedGroups?.find((group) => group?.id === groupId)
        ?.verifications) {
        finalPrice = finalPrice + parseInt(verificationType?.type?.price);
      }

      return finalPrice;
    },
  },
});
