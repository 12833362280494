import { internalApiRequest } from '@/shared/services/apiService';

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchTemplates = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/contract/settings/template/list`,
  });
};

/**
 * @param {Number|String} workspaceId
 * @param {Object} data
 * @return {Promise<AxiosResponse<*>>}
 */
export const createTemplate = ({ workspaceId, data }) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v2/workspace/${workspaceId}/contract/settings/template`,
    data,
  });
};

/**
 * @param {Number|String} workspaceId
 * @param {Number|String} templateId
 * @return {Promise<AxiosResponse<*>>}
 */
export const deleteTemplate = ({ workspaceId, templateId }) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v2/workspace/${workspaceId}/contract/settings/template/${templateId}`,
  });
};
