import { defineStore } from 'pinia';
import { fetchSettings } from '@/features/contracts/services/signatureFooterSettingsService';

export const useSignatureFooterSettingsStore = defineStore('signatureFooterSettings', {
  state: () => ({
    settings: {
      enabledLastname: false,
      enabledName: false,
      enabledOrganizationName: false,
      enabledOrganizationPosition: false,
    },
  }),
  actions: {
    fetchSettings(workspaceId) {
      return fetchSettings(workspaceId).then((resp) => {
        this.settings = { ...resp };
      });
    },
  },
  getters: {
    enabledLastname: (state) => {
      return state.settings?.enabledLastname;
    },
    enabledName: (state) => {
      return state.settings?.enabledName;
    },
    enabledOrganizationName: (state) => {
      return state.settings?.enabledOrganizationName;
    },
    enabledOrganizationPosition: (state) => {
      return state.settings?.enabledOrganizationPosition;
    },
  },
});
