<template>
  <v-container class="pl-0" fluid>
    <v-row align="center" justify="start">
      <v-col class="col-12">
        <v-divider></v-divider>
      </v-col>

      <v-col class="col-12">
        <span class="person-verification__block-title">{{ $t('verification.select') }}</span>
      </v-col>
    </v-row>

    <v-row align="center" justify="start" no-gutters>
      <v-col
        v-for="item in predefinedVerificationGroups"
        :key="item.id"
        class="col-12 person-verification-settings__choice mt-2"
      >
        <v-checkbox
          v-model="selectedVerifications"
          color="success"
          class="mt-0 mb-1 pr-3"
          :value="item.id"
          hide-details
          dense
        >
          <template v-slot:label>
            <span class="verification-option__label">
              {{ getGroupTitle(item.name) }}

              <span
                class="ml-2 px-2 py-1 person-verification-settings__credits font-weight-medium text-truncate"
              >
                {{
                  $tc('general.credits', 1, {
                    credits: verificationGroupsStore.verificationGroupPrice(item.id),
                  })
                }}
              </span>
            </span>
          </template>
        </v-checkbox>

        <v-row
          v-if="'auth.group.default.bank_id' === item.name"
          class="pb-2"
          align="center"
          justify="start"
        >
          <v-col
            v-for="logo in bankIdCompaniesLogos"
            :key="logo.name"
            class="pr-2"
            :class="`col-${logoItemColLength}`"
          >
            <v-img
              :src="logo.image"
              :alt="logo.name"
              width="auto"
              height="100%"
              max-height="1.5rem"
              contain
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import { bankIdCompaniesLogos } from '@/common/variables/bankIdCompaniesLogos';
import { useVerificationGroupsStore } from '@/features/contracts/stores/verificationGroups';
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities';

export default defineComponent({
  name: 'SignIdentityVerificationSettings',
  props: {
    signIdentityEmail: {
      type: String,
      required: true,
    },
  },
  setup() {
    const signIdentitiesStore = useSignIdentitiesStore();
    const verificationGroupsStore = useVerificationGroupsStore();

    return {
      bankIdCompaniesLogos,
      signIdentitiesStore,
      verificationGroupsStore,
    };
  },
  data() {
    return {
      loaded: true,
      selectedVerifications: [],
    };
  },
  computed: {
    docsPersonId() {
      return this.signIdentitiesStore.docsPersonIdBySignIdentityEmail(this.signIdentityEmail);
    },
    isLoading() {
      return !this.loaded;
    },
    logoItemColLength() {
      return Math.floor((this.$vuetify.breakpoint.mobile ? 12 : 6) / bankIdCompaniesLogos.length);
    },
    predefinedVerificationGroups() {
      const groups = this.verificationGroupsStore.predefinedVerificationGroups;

      if (groups?.length) {
        return groups?.sort((a, b) => a.id - b.id);
      }

      return groups;
    },
  },
  watch: {
    selectedVerifications: {
      handler: function (value) {
        this.verificationGroupsStore.updateSignIdentitiesListOfGroup(this.signIdentityEmail, value);
      },
    },
  },
  mounted() {
    if (this.docsPersonId) {
      this.loaded = false;

      this.verificationGroupsStore
        .loadSignIdentityListOfGroups(this.$route.params?.contractId, this.signIdentityEmail)
        .then(() => {
          this.loaded = true;
          this.selectedVerifications =
            this.verificationGroupsStore.verificationGroupsIdsBySignIdentityEmail(
              this.signIdentityEmail,
            );
        });
    }
  },
  methods: {
    getGroupTitle(name) {
      return name?.includes('auth.group.default') ? this.$t(name) : name;
    },
  },
});
</script>

<style lang="scss" scoped>
.person-verification {
  &__block-title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }
}

.person-verification-settings {
  &__credits {
    background-color: #6decaf;
    border-radius: 50px;
    font-size: 0.6rem;
  }

  &__logos {
    height: 20px;
    margin: 0 0.4rem;
  }

  &__choice {
    border-bottom: solid 1px #e7eaeb !important;
    flex-wrap: wrap;

    &:last-child {
      border-bottom: unset !important;
    }
  }
}

.verification-option__label {
  color: #424242;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17.5px;
}
</style>
